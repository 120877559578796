import React, { ForwardRefExoticComponent, HTMLAttributes, RefAttributes, forwardRef } from 'react';
import styled from 'styled-components';

import ellie_hopp from '../assets/ellie_hopp.jpg';
import { StyledLink } from './StyledLink';

interface GiftProps extends HTMLAttributes<HTMLDivElement> {}

export const Gifts: ForwardRefExoticComponent<GiftProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  GiftProps
>(({}: GiftProps, ref) => {
  return (
    <Block ref={ref}>
      <Background />
      <Image>
        <img src={ellie_hopp} alt="Familiebilde" loading="lazy" width="33%" />
      </Image>
      <Content>
        <h2>Gaver</h2>
        <p>
          Gaveliste på{' '}
          <a href="https://www.kitchn.no/onskeliste/159633" target="_blank">
            Kitchn
          </a>
          .
          <br />
          <br />
          <br />
          Andre ønsker:
          <ul>
            <li>Sengetøy</li>
            <li>Tilskudd til bryllupsreise</li>
          </ul>
        </p>
        {/* <StyledLink path="#">Les mer</StyledLink> */}
      </Content>
    </Block>
  );
});

const Block = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 200px;
  padding: 30px 0;
  justify-content: space-around;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Background = styled.div`
  position: absolute;
  background-color: #f3e6e9;
  top: 0;
  bottom: 0;
  left: 23%;
  right: 0;
  z-index: -1;

  @media (max-width: 1000px) {
    right: 5%;
  }

  @media (max-width: 700px) {
    left: 0;
    right: 0;
    top: 40%;
  }
`;

const Image = styled.div`
  display: flex;
  width: 33%;

  @media (max-width: 700px) {
    width: 80%;
  }

  img {
    display: block;
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 2px;
  }
`;

const Content = styled.div`
  width: 40%;

  p {
    margin-bottom: 0;
  }

  @media (max-width: 700px) {
    width: 90%;
    margin-top: 20px;
  }
`;
