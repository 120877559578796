import React, { ForwardRefExoticComponent, HTMLAttributes, RefAttributes, forwardRef } from 'react';
import styled from 'styled-components';
import { path } from '../pages/ResponsePage';
import { StyledLink } from './StyledLink';

interface ResponseProps extends HTMLAttributes<HTMLDivElement> {}

export const Response: ForwardRefExoticComponent<ResponseProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  ResponseProps
>(({}: ResponseProps, ref) => {
  return (
    <Block ref={ref}>
      <Line />
      <h2>Svar på invitasjonen</h2>
      <h5>(pliis!)</h5>
      <StyledLink path={path}>
        Trykk her for å sende inn svaret ditt
      </StyledLink>
      <Line />
    </Block>
  );
});

const Block = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    margin: 0;
    line-height: 1.5;
    text-align: center;
  }
  h5 {
    margin: 0 0 10px;
  }
`;

const Line = styled.hr`
  width: 60%;
  background-color: #555;
  margin: 60px 0;
  
  @media (max-width: 700px) {
    width: 80%;
  }
`;
