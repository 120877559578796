import React, {
  useState,
  useRef,
  useEffect,
  ForwardRefExoticComponent,
  HTMLAttributes,
  RefAttributes,
  forwardRef,
  MutableRefObject,
  ReactNode,
} from 'react';
import styled, { css } from 'styled-components';
import { useDraggable } from 'react-use-draggable-scroll';
import underline from '../assets/underline.png';

interface ProgramProps extends HTMLAttributes<HTMLDivElement> {}

const FadeInSection = ({ children }: { children: ReactNode }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const domRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setIsVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);

  return (
    <FadeElement isVisible={isVisible} ref={domRef}>
      {children}
    </FadeElement>
  );
};

export const Program: ForwardRefExoticComponent<ProgramProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  ProgramProps
>(({}: ProgramProps, ref) => {
  const dragRef = useRef<HTMLSpanElement>(null) as MutableRefObject<HTMLSpanElement>;
  const { events } = useDraggable(dragRef, { applyRubberBandEffect: true });

  const programElements = [
    { time: '14:00', name: 'Seremoni' },
    { time: '15:00', name: 'Mingling' },
    { time: '18:00', name: 'Middag' },
    { time: '21:00', name: 'Kaker, dans og festligheter' },
  ];

  return (
    <Block ref={ref}>
      <Content {...events} ref={dragRef}>
        <h1>Program</h1>
        <ul>
          {programElements.map((element) => (
            <FadeInSection key={element.name}>
              <li>{element.time}</li>
              <p>{element.name}</p>
            </FadeInSection>
          ))}
        </ul>
        <img src={underline} alt="illustrasjon" />
      </Content>
    </Block>
  );
});

const Block = styled.div`
  display: flex;
  justify-content: center;
  width: 60%;
  background-color: #dde6da;
  color: #555;
  margin: 150px auto;
  border-radius: 5px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);

  @media (max-width: 700px) {
    width: 80%;
  }
`;

const Content = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  h1 {
    text-align: center;
    margin-top: 30px;
  }

  p {
    margin: 0;
    &:before {
      content: '-';
      margin-right: 10px;
    }
  }

  ul {
    width: 50%;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;

  li {
    margin-top: 30px;
  }

  img {
    filter: contrast(0.2);
    margin: 40px 0 20px;
    width: 40%;

    @media (max-width: 900px) {
      width: 80%;
    }
  }
`;

const FadeElement = styled.div<{ isVisible: boolean }>`
  opacity: 0;
  transform: translateY(8vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      transform: none;
      visibility: visible;
    `}
`;
