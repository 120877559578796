import React, { useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '../components/Breadcrumbs';
import alle_tre from '../assets/alle_tre.jpg';
import { useScrollToTop } from '../hooks/useScrollToTop';

export const path = '/informasjon';

export const InformationPage = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useScrollToTop(scrollRef);

  return (
    <Container ref={scrollRef}>
      <Breadcrumbs href="/" />
      <Content>
        <h1>Informasjon om bryllupet</h1>
        <Line color="#d5daeb" />
        <List>
          <li>Antrekk: Dress.</li>
          <ul>
            <li>
              Dress er mindre formelt enn mørk dress. Kort oppsummert, mørk eller lys dress for menn. Kjole, skjørt
              eller buksedress for damer (Hvitt er forbeholdt bruden &#128112;). Bunad er alltid lov!
            </li>
          </ul>
          <li>Mye av dagen, inkludert vielsen vil foregå utendørs. Sjekk værmeldingen og ta med klær/sko deretter!</li>
          <ul>
            <li>PS: Vi har bestilt godt vær, men både plan B og plan C er klare dersom været ikke spiller på lag.</li>
          </ul>
          <li>Vielsen blir gjennomført i umiddelbar nærhet til Tveiter Gård, i flotte grønne omgivelser.</li>
          <li>
            Etter vielsen forsvinner vi for å ta bilder. Det blir lagt opp til mingling, aktiviteter og forfriskninger
            på områdene til Tveiter Gård.
          </li>
          <li>
            Vi har med oss en fotograf som vil forevige hele dagen. Dere får tilgang til disse bildene så fort de er
            klare, og kan dermed nyte hele dagen uten å se den gjennom kameralinsen.
          </li>
          <ul>
            <li>Det er selvsagt bare hyggelig om du også ønsker å ta dine egne bilder/filmer.</li>
          </ul>
          <li>
            Middagen blir servert inne på gården, etter en fast meny. Si fra til oss dersom du har allergier, ønsker
            vegetar/veganske alternativer, eller har andre preferanser som skal tas hensyn til!
          </li>
          <ul>
            <li>
              Det blir åpen bar etter middagen, og det er ikke nødvendig å ha med seg penger til annet enn evt.
              transport.
            </li>
          </ul>
          <li>
            For dere som har meldt interesse blir det middag på Øvre Sem, fredag 19.00. Det vil bli servert hovedrett og
            dessert, samt drikke både med og uten alkohol.
          </li>
          <li>
            For informasjon om overnatting og transport, se <Link to="/lokasjon">her</Link>.
          </li>
          <li>
            Det er begrenset med plass på Tveiter Gård, og selskapet er kun åpent for dere med en invitasjon. Ta kontakt
            med oss om det dukker opp nye ønsker om å ta med seg en date.
          </li>
        </List>
        <p>
          Send oss en mail på <a href="mailto:bryllup@siljeogfredrik2023.no">bryllup@siljeogfredrik2023.no</a> hvis du
          lurer på noe mer!
        </p>
        <img src={alle_tre} alt="Familiebilde" loading="lazy" width="90%" />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-left: 200px;

  h1 {
    margin-bottom: 0;
  }

  img {
    width: 90%;
    padding: 40px 0;
  }

  @media (max-width: 900px) {
    margin: 0 50px;
  }

  @media (max-width: 700px) {
    img {
      width: 100%;
    }
  }
`;

const Line = styled.hr`
  width: 10%;
  margin: 10px 0;

  @media (max-width: 900px) {
    width: 20%;
  }
`;

const List = styled.ul`
  max-width: 600px;

  > li {
    font-size: 18px;
    list-style-type: '\u2665   ';

    ::marker {
      font-size: 14px;
    }

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  > ul {
    margin-top: 5px;
    font-size: 16px;
  }
`;
