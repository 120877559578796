import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

interface Linkprops {
  path: string;
  children: string | React.ReactNode;
  secondary?: boolean;
}

export const StyledLink = ({ path, children, secondary }: Linkprops) => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  if (width >= 700) {
    return (
      <StyledLinkElement to={path}>
        {children}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
          <g>
            <path
              d="M10,6,6,10,10,14"
              fill="none"
              stroke="currentColor"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="1"
            ></path>
          </g>
          <line
            opacity="0"
            x1="2"
            x2="10"
            y1="10"
            y2="10"
            fill="none"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          ></line>
        </svg>
      </StyledLinkElement>
    );
  }
  return (
    <Button
      sx={{ marginTop: '20px' }}
      onClick={() => navigate(path)}
      variant="contained"
      color={secondary ? 'secondary' : 'primary'}
    >
      {children}
    </Button>
  );
};

const StyledLinkElement = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #555;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: black;
    text-decoration-color: #555;

    svg line {
      opacity: 1;
      -webkit-transition-delay: 50ms;
      transition-delay: 50ms;
    }

    svg path {
      -webkit-transform: translateX(-4px);
      transform: translateX(-4px);
    }
  }

  svg:not([fill]) {
    fill: currentColor;
  }

  svg {
    margin-left: 4px;
    -webkit-transform: translateY(0);
    transform: translateY(0) rotate(180deg);
    color: #555;
    height: 42px;
  }

  svg line {
    -webkit-transition: opacity 250ms ease 0ms;
    transition: opacity 250ms ease 0ms;
  }

  svg path {
    -webkit-transition: -webkit-transform 250ms ease;
    transition: -webkit-transform 250ms ease;
    transition: transform 250ms ease;
    transition: transform 250ms ease, -webkit-transform 250ms ease;
  }
`;
