import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Home } from './pages/Home';
import { ResponsePage, path as responsePath } from './pages/ResponsePage';
import { LocationPage, path as locationPath } from './pages/LocationPage';
import { InformationPage, path as informationPath } from './pages/InformationPage';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#d5daeb',
    },
    secondary: {
      main: '#f1e1e5',
    },
    info: {
      main: '#0288d1',
    },
  },
  typography: {
    fontFamily: ['Rubik', 'Roboto', 'Arial', 'Helvetica', 'sans-serif'].join(','),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path={responsePath} element={<ResponsePage />} />
          <Route path={locationPath} element={<LocationPage />} />
          <Route path={informationPath} element={<InformationPage />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
