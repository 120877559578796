import React, { useRef, createContext, Context, RefObject } from 'react';
import styled from 'styled-components';
import { Hero } from '../components/Hero';
import { Response } from '../components/ResponseBlock';
import { Location } from '../components/LocationBlock';
import { Program } from '../components/ProgramBlock';
import { Gifts } from '../components/GiftBlock';
import { Information } from '../components/InformationBlock';
import { SideBar } from '../components/SideBar';

export const RefContext = createContext<{ name: string; ref: RefObject<HTMLDivElement> }[] | undefined>(undefined);

export const Home = () => {
  const topRef = useRef<HTMLDivElement>(null);
  const responseRef = useRef<HTMLDivElement>(null);
  const locationRef = useRef<HTMLDivElement>(null);
  const programRef = useRef<HTMLDivElement>(null);
  const giftRef = useRef<HTMLDivElement>(null);
  const informationRef = useRef<HTMLDivElement>(null);

  const refList = [
    { name: 'Topp', ref: topRef },
    { name: 'Svar', ref: responseRef },
    { name: 'Hvor', ref: locationRef },
    { name: 'Program', ref: programRef },
    { name: 'Gaver', ref: giftRef },
    { name: 'Informasjon', ref: informationRef },
  ];

  return (
    <RefContext.Provider value={refList}>
      <Container>
        <Col>
          <Hero ref={topRef} />
          <Response ref={responseRef} />
          <Location ref={locationRef} />
          <Program ref={programRef} />
          <Gifts ref={giftRef} />
          <Information ref={informationRef} />
        </Col>
        <Col>
          <SideBar />
        </Col>
      </Container>
    </RefContext.Provider>
  );
};

const Container = styled.div`
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-gap: 10px;
`;

const Col = styled.div`
  flex-direction: column;
  grid-column: auto / span 9;

  &:last-child {
    grid-column: auto / span 3;
  }

  @media (max-width: 1000px) {
    grid-column: auto / span 12;

    &:last-child {
      display: none;
    }
  }
`;
