import React, { RefObject, useContext } from 'react';
import styled from 'styled-components';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import { RefContext } from '../pages/Home';
import { ResetStyle } from '../fonts/resetStyle';

export const SideBar = () => {
  const refList = useContext(RefContext);
  const { height } = useWindowDimensions();

  const infoContent = ['Tveiter Gård', 'Kl. 14.00', 'Antrekk: Dress', 'S.U. innen 01.06'];

  const handleScroll = (componentRef: RefObject<HTMLDivElement>) => {
    if (componentRef && componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Bar height={height}>
      <HeaderNav onClick={() => refList && handleScroll(refList[0].ref)}>
        <Header>Silje & Fredrik</Header>
        <Line color="#d5daeb" />
        <Date>12.08.2023</Date>
      </HeaderNav>
      <InfoList>
        {infoContent.map((info) => (
          <InfoItem key={info}>
            <Info>{info}</Info>
          </InfoItem>
        ))}
      </InfoList>
    </Bar>
  );
};

const Bar = styled.nav<{ height?: number }>`
  max-height: ${(props) => props.height && `${props.height}px`};
  position: sticky;
  display: flex;
  flex-wrap: wrap;
  top: 5%;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const Header = styled.h1`
  font-size: 2rem;
  font-weight: 200;
  letter-spacing: 5px;
  width: 100%;
  text-align: center;
  margin: 0;
`;

const Line = styled.hr`
  width: 15%;
`;

const Date = styled.h3`
  font-weight: 200;
  letter-spacing: 5px;
  width: 100%;
  text-align: center;
  margin: 0 0 30px;
`;

const HeaderNav = styled.button`
  ${ResetStyle};
  cursor: pointer;
  width: 100%;
  transition: all 0.2s;

  &:hover {
    color: #d5daeb;
  }
`;

const InfoList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 0;
`;

const InfoItem = styled.li`
  list-style-type: '\u2665';

  ::marker {
    color: #f3e6e9;
  }
`;

const Info = styled.p`
  font-weight: 200;
  line-height: 28px;
  width: 100%;
  text-align: center;
  margin: 0 0 30px 8px;
`;
