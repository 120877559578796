import React, { useRef } from 'react';
import styled from 'styled-components';
import { Breadcrumbs } from '../components/Breadcrumbs';
import tveiter_utsikt from '../assets/tveiter_utsikt.jpg';
import ovre_sem from '../assets/sem_gjestegaard.jpg';
import { useScrollToTop } from '../hooks/useScrollToTop';

export const path = '/lokasjon';

export const LocationPage = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useScrollToTop(scrollRef);

  return (
    <Container ref={scrollRef}>
      <Breadcrumbs href="/" />
      <Content>
        <h1>Tveiter Gård</h1>
        <Line color="#d5daeb" />
        <h3>Parkering</h3>
        <p>
          Det er et begrenset antall parkeringsplasser tilgjengelig inne ved Tveiter Gård. Hvis du ikke kjører en
          fullsatt bil vil vi anbefale å bruke pakeringen ved Øvre Sem og benytte felles transport derfra inn til
          Tveiter.
          <br />
          Ellers er det flere utfartsparkeringer i nærheten. Nærmeste er 1,7km fra Tveiter gård.
        </p>
        <h3>Transport</h3>
        <p>
          Vi organiserer felles transport inn fra Øvre Sem til Tveiter. Oppmøte er{' '}
          <a href="https://goo.gl/maps/GVaae6zfUh4PzDe59" target="_blank">
            utenfor Øvre Sem
          </a>{' '}
          kl. 13.15. Kontaktpersoner for transport er Bjørn Haraldseth (90562920) og Kjell Johansson (91688751).
        </p>
        <br />
        <p>
          På slutten av kvelden vil vi bestille maxi-taxi fra Tveiter til Asker Stasjon dersom flere har behov for
          det. Gi en lyd til Fredrik dersom du ønsker å benytte deg av taxitilbudet.
        </p>
        <h3>Overnatting</h3>
        <p>
          For dere som trenger overnatting anbefaler vi å benytte{' '}
          <a href="https://www.ovresem.no/" target="_blank">
            Øvre Sem Gård
          </a>
          {'. '}
          <br />
          <br />
          Gården ligger på andre siden av Semsvann for Tveiter Gård, og det er en fin gåtur på ca. 2km mellom gårdene.
          Det blir selvsagt satt opp transport ifbm med seremonien for de som ønsker det. Øvre Sem Gård har egne
          parkeringsplasser.
          <br />
          <br />
          Her har vi fått en god pris på overnatting, og det blir gode muligheter for sosialisering før den store dagen.
          <br />
          <br />
          Her legger vi også opp til en felles bedre middag på fredag kveld, laget på lokale råvarer. Det blir selvsagt
          mulig å delta på middagen uten å overnatte på gården.
          <br />
          <br />
          <b>NB!</b> Vi trenger tilbakemelding så snart som mulig fra dere som ønsker overnatting og evt middag. Øvre
          Sem har behov for å kunne planlegge helgens aktiviteter i god tid. Send oss en mail på{' '}
          <a href="mailto:bryllup@siljeogfredrik2023.no">bryllup@siljeogfredrik2023.no</a>.
        </p>
        <h4>Priser</h4>
        <ul>
          <li>Enkeltrom: 1390,-</li>
          <li>Dobbeltrom: 1590,-</li>
        </ul>
        <img src={ovre_sem} alt="Øvre Sem Gård" loading="lazy" width="90%" />
        <History>
          <h2>Tveiter Gård</h2>
          <p>
            Gården har gjennom tidene hatt mange forskjellige eiere, men på 1800-tallet og frem til siste verdenskrig
            var Holtsmark-familien fra Sylling i Lier som kom til å sette sitt preg på Tveiter og ellers hele
            Sems-området.{' '}
          </p>
          <p>
            Bent Holtsmark (1823-1903) kom til Asker i 1849 og kjøpte Tveiter. Han rev ned den gamle bebyggelsen på
            gården og bygget nytt.
          </p>
          <p>
            Den gamle hovedbygningen lå litt lenger ned mot Semsvannet og ble revet og solgt til Asker kommune i 1858.
            Bygningen ble flyttet til Østenstad og tjente som skole til omkring 1890, senere til boliger. Bygningen
            brant nedi slutten av 1960-årene.
          </p>
          <p>
            Bent Holtsmark bygget den nye hovedbygningen høyere oppe i bakkeskråningen, slik den ser ut i dag. Den ble
            vel bygget like etter at den gamle var revet.
          </p>
          <p>
            Til gården hører fire husmannsplasser, nemlig Tveitersetra, Kølabonn, Mobråtan og Svensrud. Mobråtan er
            kjent fra filmen ”Mormor og de åtte ungene i skogen”.
          </p>
          <p>Gården ble landskjent som reklame i fjernsynet for en kjent melkesjokolade.</p>
          <p>(Utdrag fra artikkel i Budstikka)</p>
          <img src={tveiter_utsikt} alt="Tveiter utsikt" loading="lazy" width="90%" />
        </History>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-left: 200px;
  margin-right: 100px;

  h1 {
    margin-bottom: 0;
  }

  img {
    width: 90%;
    padding: 40px 0;
  }

  @media (max-width: 900px) {
    margin: 0 50px;
  }

  @media (max-width: 700px) {
    img {
      width: 100%;
    }
  }
`;

const Line = styled.hr`
  width: 10%;
  margin: 10px 0;

  @media (max-width: 900px) {
    width: 20%;
  }
`;

const History = styled.span`
  width: 75%;
  padding-bottom: 40px;

  p {
    line-height: 1.5;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;
