import React, { ForwardRefExoticComponent, HTMLAttributes, RefAttributes, forwardRef } from 'react';
import styled from 'styled-components';

import maps from '../assets/maps.jpg';
import { path } from '../pages/LocationPage';
import { StyledLink } from './StyledLink';

interface LocationProps extends HTMLAttributes<HTMLDivElement> {}

export const Location: ForwardRefExoticComponent<LocationProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  LocationProps
>(({}: LocationProps, ref) => {
  return (
    <Block ref={ref}>
      <Content>
        <h2>Tveiter Gård</h2>
        <p>
          Både vielsen og bryllupsfesten finner sted på Tveiter Gård, på vestiden av Semsvann.
          <br />
          <StyledLink path={path}>Overnatting, parkering og transport</StyledLink>
        </p>
      </Content>
      <Image href="https://goo.gl/maps/YJqtmWjoK6pvH74PA" target="_blank" rel="noreferrer">
        <img src={maps} alt="Tveiter Gård kart" loading="lazy" width="40%" />
      </Image>
    </Block>
  );
});

const Block = styled.div`
  display: flex;
  width: 100%;
  margin-top: 100px;
  justify-content: space-around;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Image = styled.a`
  display: flex;
  width: 40%;
  cursor: pointer;
  position: relative;

  @media (max-width: 700px) {
    width: 80%;
    margin-top: 20px;
  }

  > img {
    display: block;
    width: 100%;
    border-radius: 2px;
    object-fit: contain;
  }
`;

const Content = styled.div`
  width: 40%;

  @media (max-width: 700px) {
    width: 90%;
  }
`;
