import React from 'react';
import styled from 'styled-components';

export const Breadcrumbs = ({ href }: { href: string }) => {
  return (
    <OneUpLink>
      <PathElement href={href}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
          <g>
            <path
              d="M10,6,6,10,10,14"
              fill="none"
              stroke="currentColor"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="1"
            ></path>
          </g>
          <line
            opacity="0"
            x1="2"
            x2="10"
            y1="10"
            y2="10"
            fill="none"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          ></line>
        </svg>
        <span>Hjem</span>
      </PathElement>
    </OneUpLink>
  );
};

const PathElement = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #888;

  &:visited {
    color: #888;
  }

  &:hover {
    color: #111;

    svg line {
      opacity: 1;
      -webkit-transition-delay: 50ms;
      transition-delay: 50ms;
    }

    svg path {
      -webkit-transform: translateX(-4px);
      transform: translateX(-4px);
    }
  }

  > span {
    margin-left: 4px;
    text-decoration: none;
    font-size: 20px;
  }
`;

const OneUpLink = styled.span`
  display: inline-flex;
  cursor: pointer;
  position: absolute;
  top: 5%;
  left: 5%;

  @media (max-width: 900px) {
    position: unset;
    display: flex;
    width: 100%;
    margin: 10px;
  }

  svg:not([fill]) {
    fill: currentColor;
  }

  svg {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  svg line {
    -webkit-transition: opacity 250ms ease 0ms;
    transition: opacity 250ms ease 0ms;
  }

  svg path {
    -webkit-transition: -webkit-transform 250ms ease;
    transition: -webkit-transform 250ms ease;
    transition: transform 250ms ease;
    transition: transform 250ms ease, -webkit-transform 250ms ease;
  }
`;
