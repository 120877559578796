import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Info } from '@styled-icons/material-outlined/Info';

import { Breadcrumbs } from '../components/Breadcrumbs';
import { useScrollToTop } from '../hooks/useScrollToTop';

export const path = '/svar';

export const ResponsePage = () => {
  const form = useRef<HTMLFormElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<string>('1');

  useScrollToTop(scrollRef);

  const sendEmail = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form && form.current) {
      emailjs.sendForm('service_onkfg71', 'template_px9hitj', form.current, 'Mc8xvVprOj8CsJcXz').then(
        (result) => console.log(result),
        (error) => console.log(error)
      );
    }

    (e.target as HTMLFormElement).reset();
  };

  const handleRange = (e: Event) => {
    setValue((e as unknown as React.ChangeEvent<HTMLInputElement>).target.value);
  };

  const createGuestInputs = () => {
    let inputIndex = [];
    for (let i = 0; i < parseInt(value, 10); i++) {
      inputIndex.push(i + 1);
    }
    return inputIndex.map((index) => (
      <AdditionalGuestsWrapper key={index}>
        <TextField label={`Navn #${index}`} variant="standard" name={`name_${index}`} required />
      </AdditionalGuestsWrapper>
    ));
  };

  return (
    <Container ref={scrollRef}>
      <Breadcrumbs href="/" />
      <Form ref={form} onSubmit={(e: React.MouseEvent<HTMLFormElement>) => sendEmail(e)}>
        <h1>Registrer deg på gjestelisten!</h1>
        <TextField label="E-post" variant="standard" name="reply_to" required />
        <FormControl>
          <FormLabel id="slider-label">{`Antall påmeldte: ${value}`}</FormLabel>
          <Slider
            defaultValue={1}
            valueLabelDisplay="auto"
            step={1}
            min={1}
            max={4}
            onChange={(event: Event) => handleRange(event)}
            name="registered_number"
          />
        </FormControl>
        {parseInt(value, 10) > 0 && createGuestInputs()}
        <TextField
          sx={{ marginTop: '80px !important', maxWidth: '300px !important' }}
          label="Melding (allergier eller annet)"
          multiline
          minRows={4}
          variant="outlined"
          name="message"
        />
        <FormControl>
          <LabelWrapper>
            <FormLabel id="radio-group">
              Ønsker du å holde en tale <ResponsiveBr />
              under middagen?
              <Tooltip
                enterTouchDelay={10}
                arrow
                title="Toastmaster trenger oversikt for å planlegge kveldens kjøreplan"
              >
                <Info size={20} />
              </Tooltip>
            </FormLabel>
          </LabelWrapper>
          <RadioGroup aria-labelledby="radio-group" defaultValue="Nei" name="speech" row>
            <FormControlLabel value="Nei" control={<Radio />} label="Nei" />
            <FormControlLabel value="Ja" control={<Radio />} label="Ja" />
          </RadioGroup>
        </FormControl>
        <Button type="submit" variant="contained">
          Svar
        </Button>
        <p>
          Hvis du har spørsmål kan de sendes til{' '}
          <a href="mailto:rsvp@siljeogfredrik2023.no">rsvp@siljeogfredrik2023.no</a>
        </p>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  background-color: #fff;

  @media (max-width: 700px) {
    background-color: #fcfcfc;
  }
`;

const Form = styled.form`
  position: relative;
  top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  background: #fcfcfc;
  padding: 30px 70px;
  border-radius: 4px;
  box-shadow: 10px 20px 25px rgba(0, 0, 0, 0.15);

  p {
    margin-top: 40px;
    font-size: 14px;
  }

  > div {
    margin-top: 10px;
    width: 100%;
    max-width: 350px;
  }

  #slider-label,
  #radio-group,
  button {
    margin-top: 16px;
  }

  @media (max-width: 900px) {
    position: unset;
  }

  @media (max-width: 700px) {
    max-width: unset;
    width: 100%;
    padding: 30px 40px;
  }
`;

const AdditionalGuestsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;

  label {
    width: fit-content;
  }

  svg {
    margin-left: 10px;
    color: #111;
    cursor: pointer;
  }
`;

const ResponsiveBr = styled.br`
  @media (min-width: 450px) {
    display: none;
  }
`;
