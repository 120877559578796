import React, { forwardRef, ForwardRefExoticComponent, HTMLAttributes, RefAttributes } from 'react';
import styled from 'styled-components';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import bakgrunn_1 from '../assets/bakgrunn_1.jpg';
import bakgrunn_2 from '../assets/bakgrunn_2.jpg';
import bakgrunn_3 from '../assets/bakgrunn_3.jpg';
import bakgrunn_4 from '../assets/bakgrunn_4.jpg';
import { NavBar } from './NavBar';

const slideImages = [bakgrunn_1, bakgrunn_2, bakgrunn_3, bakgrunn_4];

interface HeropProps extends HTMLAttributes<HTMLDivElement> {}

export const Hero: ForwardRefExoticComponent<HeropProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  HeropProps
>(({}: HeropProps, ref) => {
  return (
    <div ref={ref}>
      <StyledFade duration={5000} transitionDuration={1500} arrows={false} pauseOnHover={false}>
        {slideImages &&
          slideImages.length > 0 &&
          slideImages.map((image, index) => (
            <div key={`${image}-${index}`}>
              <SlideImage src={image} alt="Bakgrunnsbilde" width="100%" />
              <Under>
                <Caption>
                  <h1>Silje & Fredrik</h1>
                  <Line color="black" />
                  <h3>12.08.2023</h3>
                </Caption>
                <NavBar />
              </Under>
            </div>
          ))}
      </StyledFade>
    </div>
  );
});

const StyledFade = styled(Fade)`
  display: flex;
  position: relative;
  width: 100%;
`;

const SlideImage = styled.img`
  display: flex;
  object-fit: cover;
  height: 100vh;
  width: 100%;
  border-radius: 2px;

  @media (max-width: 1000px) {
    object-fit: contain;
    height: unset;
  }
`;

const Under = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Caption = styled.span`
  display: none;
  position: absolute;
  z-index: 1000;
  top: 20px;
  right: 100px;
  flex-direction: column;
  align-items: center;

  h1 {
    font-weight: 200;
    font-size: 60px;
    letter-spacing: 5px;
    text-align: right;
    margin-bottom: 0;
    line-height: 1;
  }
  h3 {
    font-weight: 400;
    letter-spacing: 5px;
    text-align: right;
    margin-top: 0;
    line-height: 1;
  }

  @media (max-width: 1000px) {
    display: flex;
  }

  @media (max-width: 900px) {
    position: initial;
    align-items: center;
    flex: 1;
    order: 1;
    flex-basis: 100%;

    h3 {
      margin: 0 0 40px;
    }
  }
  @media (max-width: 700px) {
    h1 {
      font-size: 40px;
      text-align: center;
    }
    h3 {
      font-size: 14px;
      text-align: center;
      margin-bottom: 0;
    }
  }
`;

const Line = styled.hr`
  margin: 15% 0;
  width: 15%;

  @media (max-width: 1220px) {
    display: none;
  }

  @media (max-width: 900px) {
    display: block;
    margin: 20px 0;
  }
`;
