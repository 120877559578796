import React, { ForwardRefExoticComponent, HTMLAttributes, RefAttributes, forwardRef } from 'react';
import styled from 'styled-components';

import sno from '../assets/sno.jpg';
import { path } from '../pages/InformationPage';
import { StyledLink } from './StyledLink';

interface InformationProps extends HTMLAttributes<HTMLDivElement> {}

export const Information: ForwardRefExoticComponent<InformationProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  InformationProps
>(({}: InformationProps, ref) => {
  return (
    <Block ref={ref}>
      <Background />
      <Content>
        <h2>Informasjon om dagen</h2>
        <p>Her samler vi annen informasjon som kan være kjekt å få med seg.</p>
        <p>
          Send oss en mail hvis du ikke finner svar på det du lurer på:{' '}
          <a href="mailto:bryllup@siljeogfredrik2023.no">bryllup@siljeogfredrik2023.no</a>
        </p>
        <p>
          Toastmaster er Tone Haraldseth.
          <br />
          <a href="mailto:tone.haraldseth@getmail.no">tone.haraldseth@getmail.no</a>
          <br />
          <a href="tel:95754546">95754546</a>
        </p>
        <br />
        <StyledLink path={path} secondary>
          Les mer
        </StyledLink>
      </Content>
      <Image>
        <img src={sno} alt="Snø i håret" loading="lazy" width="40%" />
      </Image>
    </Block>
  );
});

const Block = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 150px;
  margin-left: 5%;
  padding: 30px 0;
  justify-content: space-around;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
`;

const Background = styled.div`
  position: absolute;
  background-color: #e6e9f3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 25%;
  z-index: -1;

  @media (max-width: 1000px) {
    right: 30%;
  }

  @media (max-width: 700px) {
    right: 0;
    bottom: 33%;
  }
`;

const Image = styled.div`
  display: flex;
  width: 40%;

  @media (max-width: 1000px) {
    margin-right: 50px;
  }

  @media (max-width: 700px) {
    width: 80%;
    margin-right: unset;
  }

  img {
    display: block;
    object-fit: contain;
    width: 100%;
    border-radius: 2px;

    @media (max-width: 700px) {
      margin-top: 30px;
    }
  }
`;

const Content = styled.div`
  width: 40%;

  p {
    margin-bottom: 0;
  }

  @media (max-width: 700px) {
    width: 90%;
    margin-bottom: 20px;
  }
`;
