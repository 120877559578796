import { css } from 'styled-components';

export const ResetStyle = css`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  appearance: none;
  touch-action: manipulation;
  text-decoration: none;
`;
