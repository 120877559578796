import React, { useContext, RefObject } from 'react';
import styled from 'styled-components';
import { RefContext } from '../pages/Home';
import { ResetStyle } from '../fonts/resetStyle';

export const NavBar = () => {
  const refList = useContext(RefContext);

  const handleScroll = (componentRef: RefObject<HTMLDivElement>) => {
    if (componentRef && componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  if (refList) {
    return (
      <Bar>
        {refList.slice(1).map((component) => (
          <ItemWrapper key={component.name}>
            <NavItem onClick={() => handleScroll(component.ref)}>
              {component.name}
            </NavItem>
          </ItemWrapper>
        ))}
      </Bar>
    );
  }
  return null;
};

const Bar = styled.nav`
  width: 100%;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 400px) {
    display: none;
  }
`;

const ItemWrapper = styled.span`
  display: flex;
  width: fit-content;
  justify-content: center;
`;

const NavItem = styled.button`
  ${ResetStyle};
  display: flex;
  position: relative;
  align-items: center;
  color: #555;
  cursor: pointer;
  height: 48px;
  padding: 0 10px;
  font-family: 'Trirong';

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '';
    color: transparent;
    background: #aaa;
    height: 1px;
    transition: all 0.5s;
  }

  &:hover {
    color: black;

    &:after {
      width: 100%;
      color: #555;
    }
  }
`;
